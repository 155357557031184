.podcast-container{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin: 70px auto;
    padding: 20px;
    max-width: 1200px;
}

.podcast-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #00090b;
    padding: 0;
    border-radius: 2px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.image-container{
    position: relative;
    width: 100%;
}

.podcast-item img{
    width: 100%;
    height: auto;
    border-radius: 2px;
    transition: transform 0.3s;
}

.podcast-item .overlay {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    opacity: 0;
    transition: opacity 0.3s;
    border-radius: 2px;
    text-align: center;
}

.podcast-item:hover .overlay{
    opacity: 1;
}

.podcast-item:hover img{
    transform: scale(1.05);
}

@media (max-width: 768px){
    .podcast-container{
        grid-template-columns: repeat(2, 1fr);
    }
    .podcast-item img{
        width: 100%;
    }
}

@media (max-width: 480px){
    .podcast-container{
        grid-template-columns: 1fr;
    }
}
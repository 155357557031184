.contact-container {
    width: 100%;
    max-width: 70vw;
    margin: 0 auto;
    padding: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
  }
  
  h1 {
    font-size: 1.5rem;
    text-align: left;
    margin-bottom: 10px;
  }
  
  p {
    font-size: 0.9rem;
    margin-bottom: 20px;
    /* color: #B22222; */
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .form-row {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-right: 20px;
  }
  
  label {
    font-size: 0.9rem;
    margin-bottom: 5px;
  }
  
  label span {
    color: #B22222;
    font-size: 0.7rem;
  }
  
  input, textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    font-size: 1rem;
    background-color: #FAF9F6;
  }
  
  textarea {
    resize: none;
  }
  
  .submit-button {
    padding: 10px 20px;
    background-color: #800000;
    color: #FAF9F6;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    font-weight: bolder;
    cursor: pointer;
    text-transform: uppercase;
    letter-spacing: 1px;
    width: 15vw;
  }
  
  .submit-button:hover {
    background-color: #B22222;
  }

  input::placeholder, textarea::placeholder{
    font-size: 0.9rem;
    color: #818589;
  }
  
.wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
   min-height: 100vh;
}
.about-us-page {
    display: flex;
    flex-direction: row; 
    justify-content: center;
    align-items: center;
    width: 90vw;
    margin: 50px 0;
    color: #fff;
    flex-wrap: wrap;
    gap: 20px; 
}

.about-us {
    padding: 20px;
    max-width: 60%; 
    flex: 1; 
}

.about-us-page img {
    max-width: 20%; 
    height: auto;
    margin: 0; 
    flex: 1; 
}

@media (max-width: 768px) {
    .about-us-page {
        flex-direction: column; 
        width: 100vw;
    }

    .about-us {
        max-width: 90%; 
    }

    .about-us h1 {
        font-size: 1.5rem;
    }

    .about-us p {
        font-size: 0.8rem;
    }

    .about-us-page img {
        display: none;
        max-width: 50%; 
        margin: 20px 0;
    }
}
.photography-page {
    padding: 10px;
    color: #00090b;
}

.filter-buttons{
    display: none;
}

.filter-buttons button{
    /* background-color: #00090b; */
    background-color: #00090b;
    color: #00090b;
    border: none;
    padding: 10px 20px;
    margin: 0 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    border-radius: 2px;
}

.filter-buttons button:hover, .filter-buttons button.active {
    background-color: #00090b;
}

.gallery{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(30vw, 1fr));
    gap: 10px;
}

.gallery img{
    width: 100%;
    height: auto;
    border-radius: 1px;
}

.podcast-detail-container{
    background-size: cover;
    background-position: center;
    background-color: rgba(0, 0, 0, 1);
    color: #fff;
    min-height: 100vh;
    overflow: hidden;
    margin: 0;
    padding: 0;
    position: relative;
    font-family: "Outfit", sans-serif;
}

.pd-content{
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 20px;
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    padding: 20px;
    background-color: rgba(0, 9, 11, 0.95);
}

.pd-content img{
    width: 30vw;
    height: auto;
    border-radius: 2px;
    max-width: 50vw;
}

.content-text{
    text-align: left;
    flex: 1 1 400px;
    max-width: 50vw;
    margin: 50px;
}

.content-text h1{
    font-weight: 700;
    font-size: 3rem;
}

.content-text h2{
    font-weight: 300;
    font-size: 1.5rem;
}

.content-text p{
    font-weight: 300;
    line-height: 2rem;
    font-size: 1.1rem;
    padding: 100;
}

.social-links {
    margin-top: 20px;
}

.social-links a{
    color: #fff;
    font-size: 24px;
    text-decoration: none;
    margin-right: 25px;
}

.content-tags{
    margin-top: 30px;
}

.content-tags span{
    background-color: #fff;
    color: #00090b;
    padding: 10px 15px;
    border-radius: 5px;
    font-size: 0.7em;
    font-weight: bold;
    margin-right: 25px;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px){
    .pd-content{
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: auto;
    }

    .pd-content img{
        width: 80vw;
        max-width: none;
        margin-bottom: 20px;
    }

    .content-text{
        max-width: 90vw;
        margin: 50px 20px;
        text-align: left;
    }

    .content-text h1{
        font-size: 1.5rem;
    }
    .content-text h2{
        font-size: 1rem;
    }
    .content-text p{
        font-size: .8rem;
    }

    .social-links a{
        font-size: 20px;
        margin-right: 15px;
    }

    .content-tags span{
        margin-left: 10px;
        padding: 7px 8px;
        font-size: 0.9em;
    }
}

@media (max-width: 480px){
    .content img{
        width: 90vw;
    }

    .social-links a{
        font-size: 18px;
        margin-right: 10px;
    }

    .content-tags span{
        margin-left: 5px;
        padding: 5px 6px;
        font-size: 0.8em;
    }
}